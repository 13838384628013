import React from 'react';
import { FormattedDate } from 'react-intl';
import classnames from 'classnames';

import Tag from '../Tag';

import * as styles from './HeroPost.module.scss';

type Props = {
  variant: 'blog' | 'event' | 'exercise' | 'default';
  title: string;
  categories?: { name: string }[];
  date?: string;
  imageSrc?: string;
  showDate?: boolean;
};

const HeroPost: React.FC<Props> = ({ title, categories, date, imageSrc, variant, showDate }) => (
  <div className={classnames(styles.heroPost, 'container')}>
    {imageSrc && (
      <div
        style={{
          backgroundImage: `url(${imageSrc})`,
        }}
        className={styles.featuredImage}
      ></div>
    )}
    <div className="is-flex is-justify-content-center">
      <div className={classnames('content', { [styles.transformWithImage]: imageSrc })}>
        <h1 className={classnames('title has-text-centered brand-a is-2', styles.heroPostTitle)}>{title}</h1>
        <div className="tags is-flex is-justify-content-center">
          {categories?.map(({ name }) => (
            <Tag isClickable={false} key={name} name={name} />
          ))}
        </div>
        {((!showDate && variant === 'exercise') || (variant !== 'event' && variant !== 'default') && (
          <div className={classnames(styles.date, 'is-flex is-justify-content-center')}>
            <p>
              <FormattedDate value={date} day="2-digit" month="short" year="numeric" />
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default HeroPost;
