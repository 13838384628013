import React, { useContext } from 'react';
import path from 'path-browserify';
import { FormattedDate } from 'react-intl';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { ChevronRight, ChevronLeft } from 'react-feather';

import { RichTextField } from '../RichTextField/RichTextField';
import Tag from '../Tag';
import { PageContext } from '../PageContext';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './tile-card.module.scss';

export interface TileCardProps {
  variant: 'blog' | 'event' | 'exercise';
  title?: string;
  image?: string;
  categories?: { name: string }[];
  date?: string;
  short?: string;
  slug: string;
  slugBase: string;
  linkText: string;
  address?: string;
  selectedCategories?: string[];
  showDate?: boolean;
}

const TileCard: React.FC<TileCardProps> = ({
  image,
  title,
  categories,
  date,
  short,
  slug,
  variant,
  address,
  selectedCategories,
  slugBase,
  linkText,
  showDate
}) => {
  const { websiteLocale } = useContext(PageContext);
  const { hasRightToLeftLanguage } = v4tov3base({...websiteLocale.attributes});
  return (
    <div className={styles.tileCard}>
      <Link to={path.join(slugBase, slug)}>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundColor: '#ebeded',
          }}
          className={styles.featuredImage}
        ></div>
      </Link>
      <div className={classnames(styles.content, 'content')}>
        <time className={`is-5 title mb-1 black-4 ${variant === 'event' ? 'brand-b' : ''}`} dateTime={date}>
          {variant === 'exercise' ? (
            showDate && <FormattedDate value={date} weekday="short" day="2-digit" month="short" />
          ) : variant === 'blog' ? (
            <FormattedDate value={date} day="numeric" month="short" year="numeric" />
          ) : (
            <FormattedDate value={date} weekday="short" day="2-digit" month="short" />
          )}
        </time>
        <Link to={path.join(slugBase, slug)} className="title is-block is-3 brand-a mt-0 mb-3">
          {title}
        </Link>
        <p className="mb-3">
          {categories?.map(({ name }) => (
            <Tag isActive={selectedCategories?.includes(name)} isClickable={false} key={name} name={name} />
          ))}
        </p>
        {variant === 'blog' ? (
          <RichTextField className="is-text-2 black-2" content={short} />
        ) : address ? (
          <p className="is-text-2 black-2">{address}</p>
        ) : (
          <p className="is-text-2 black-2">{short}</p>
        )}

        <div className="read-more">
          <Link
            to={path.join('/', slugBase, slug)}
            className={classnames(styles.linkSpacing, 'is-inline-flex is-text-2 brand-b')}
          >
            {linkText}
            <span className="icon black-3">
              {hasRightToLeftLanguage ? <ChevronLeft size={16} /> : <ChevronRight size={16} /> }        
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TileCard;
